<template>
  <div class="inner-acc">
    <div class="wrapper rowflex">
      <sidebar-account></sidebar-account>
      <article class="main">
        <div class="detail-trx">
          <a href="#" class="cvr-bg-bf back-link">Detail properties</a>
          <div class="rowflex">
            <div class="info">
              <div class="item booking">
                <div class="bhead">
                  <table>
                    <tr>
                      <td>
                        <small>Status</small>
                        <span style="background:#4D8FB9;">Listing</span>
                      </td>
                      <td>
                        <small>Booking Date</small>
                        <strong>18 Nov 2021</strong>
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- end of behead -->
                <div class="prop">
                  <figure>
                    <a href="#"><img src="/img/dummy/acc/1.jpg" width="76"/></a>
                  </figure>
                  <div class="caption">
                    <h3><a href="#">Cluster Eureka at Nevada</a></h3>
                    <div class="location cvr-bg-bf">
                      <span>Padalarang, Bandung</span>
                      <span>Furnished</span>
                    </div>
                  </div>
                </div>
                <!-- end of prop -->
                <table>
                  <tr>
                    <td>
                      <small>Transaction Type</small>
                      <ul>
                        <li>Rent</li>
                        <li>Rent to Own</li>
                        <li>Buy</li>
                      </ul>
                    </td>
                    <td>
                      <small>Rent Period</small>
                      <ul>
                        <li>6 Months</li>
                        <li>1 Year</li>
                        <li>2 Years</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small>Payment Option</small>
                      <ul>
                        <li>All Upfront</li>
                        <li>Annualy</li>
                        <li>Every 6 Months</li>
                      </ul>
                    </td>
                    <td>
                      <small>&nbsp;</small>
                      <ul>
                        <li>Every 3 Months</li>
                        <li>Monthly</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small>Rent Per Month (Upfront)</small>
                      <b>Rp 3.480.000 </b>
                    </td>
                    <td>
                      <small>Price</small>
                      <b>Rp 707.000.000</b>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- end of item booking -->
            </div>
            <!-- end of inf0 -->
            <div class="action">
              <div class="item upload">
                <span><b>Document 1.doc</b></span>
                <a href="#">Download</a>
              </div>
              <!-- end of item -->
              <div class="item upload">
                <span><b>Document 2.doc</b></span>
                <a href="#">Download</a>
              </div>
              <!-- end of item -->
            </div>
            <!-- end of action -->
          </div>
          <!-- end of rowflex -->
        </div>
        <!-- end of detail trx -->
      </article>
    </div>
    <!-- end of wrapper -->
  </div>
  <!-- end of inner mort -->
</template>

<script>
import SidebarAccount from "./SidebarAccount.vue";
// import * as moment from "moment";
// import cookie from "js-cookie";
// import $ from "jquery";

export default {
  name: "AccountMyPropertiesDetail",
  metaInfo: {
    title: "Account MyProperties Detail"
  },
  components: {
    SidebarAccount
  },
  data() {
    return {
      transactions: []
    };
  },
  mounted() {},
  methods: {}
};
</script>
